import React from "react"
import { graphql } from "gatsby"
import PageRoot from "../components/PageRoot"
import AtsCarousel from "../components/AtsCarousel"
import { renderCarouselItem } from "../utils"
import { useTranslation } from "react-i18next"

const AllChannelPage = ({ data }) => {
  const { t } = useTranslation()

  const allChannels = data.ats.channels
  return (
    <PageRoot title={t("label:channels")} showTitle>
      <AtsCarousel
        title={t("label:channels")}
        colSettings={{ xs: 24, sm: 12, md: 6, lg: 4 }}
        dataSource={allChannels}
        renderItem={renderCarouselItem}
      />
    </PageRoot>
  )
}

export const query = graphql`
  query allChannels {
    ats {
      channels(order_by: { name: asc }) {
        ...PreviewCardChannel
      }
    }
  }
`

export default AllChannelPage
